export const arrayofObjectstoCommaSeparatedstring = (
  objectArray,
  number = 0
) => {
  const arr = []

  objectArray.forEach((item, index) => {
    const itemString = item.name
    // With Links below
    // const obj = { text: theme.themeName, to: `/themes/${theme.slug.current}` }
    if (number > 0) {
      if (index < number) {
        arr.push(itemString)
      }
      if (index === number) {
        arr.push(" ...")
      }
    } else {
      arr.push(itemString)
    }
  })
  return arr.join(", ")
}
