import React from "react"

import classNames from "classnames"

import { InternalExternalLink } from "../InternalExternalLink"
import * as styles from "./Button.module.scss"

const Button = ({ to, children, className, plain, ...props }) => {
  const cls = classNames(styles.btn, className, { [styles.plain]: plain })

  if (to) {
    return (
      <InternalExternalLink className={cls} to={to} {...props}>
        {children}
      </InternalExternalLink>
    )
  }

  return (
    <button className={cls} {...props}>
      {children}
    </button>
  )
}

export { Button }
