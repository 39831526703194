// extracted by mini-css-extract-plugin
export var caption = "Mp4sBlock-module--caption--608a0";
export var cover = "Mp4sBlock-module--cover--beef0";
export var description = "Mp4sBlock-module--description--4bd3e";
export var double = "Mp4sBlock-module--double--848dc";
export var header1 = "Mp4sBlock-module--header1--a1e68";
export var header2 = "Mp4sBlock-module--header2--d61ab";
export var header3 = "Mp4sBlock-module--header3--28f27";
export var header4 = "Mp4sBlock-module--header4--d6977";
export var header5 = "Mp4sBlock-module--header5--8ecd2";
export var mediaBlock = "Mp4sBlock-module--media-block--0d4d8";
export var mobile = "Mp4sBlock-module--mobile--491ed";