export const blocksToContent = pageBlocks => {
  const blocks = []

  pageBlocks?.blocks?.forEach(block => {
    if (block._type === "imagesBlock") {
      blocks.push({
        images: block.images,
        description: block.description,
        format: block.format,
        type: "images",
      })
    }

    if (block._type === "hostedMediaDisplay") {
      blocks.push({
        url: block.mediaUrl,
        altText: block.altText,
        description: block.mediaDescription,
        format: block.format,
        type: "media",
      })
    }

    if (block._type === "mp4sBlock") {
      blocks.push({
        mp4s: block.mp4Videos,
        format: block.format,
        type: "video",
      })
    }

    if (block._type === "textBlock") {
      blocks.push({
        portableText: block._rawTextArea,
        type: "portableText",
      })
    }
  })

  return blocks
}

const getVideoUrls = ({ video }) => {
  const arr = []
  video.forEach(item => {
    arr.push(item.url)
  })
  return arr
}
