import React, { useEffect, useContext } from "react" // eslint-disable-line no-unused-vars
import { AVCOContext } from "../components/context/AVCOContext"
import { graphql } from "gatsby"
import Seo from "../components/common/seo"
import { ProjectPage } from "../components/pagecomponents/ProjectPage"

export const query = graphql`
  query ProjectTemplateQuery($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      title
      shortDescription
      _rawBodyIntroduction(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      hasCoverImage
      featuredImage {
        altText
        image {
          asset {
            gatsbyImageData(width: 1920)
            url
          }
        }
      }
      hasFeaturedVideo
      isFeaturedVideoMobile
      isMp4Portrait
      featuredVideo {
        source {
          asset {
            url
            mimeType
          }
        }
      }
      clients {
        name
        slug {
          current
        }
      }
      services {
        name
        slug {
          current
        }
      }
      displayedServices {
        name
        slug {
          current
        }
      }
      years {
        name
        slug {
          current
        }
      }
      collaborators
      team
      links {
        title
        url
      }
      pageBlocks {
        blocks {
          ... on SanityHostedMediaDisplay {
            _key
            _type
            altText
            title
            format
            mediaUrl
            mediaDescription
          }
          ... on SanityImagesBlock {
            _key
            _type
            format
            description
            images {
              altText
              caption
              image {
                asset {
                  gatsbyImageData(width: 1920, fit: FILLMAX)
                  url
                  mimeType
                }
              }
            }
          }
          ... on SanityMp4SBlock {
            _key
            _type
            format
            mp4Videos {
              altText
              mediaDescription
              mp4Upload {
                source {
                  asset {
                    url
                  }
                }
              }
              placeholderImage {
                asset {
                  gatsbyImageData(width: 1920)
                }
              }
            }
          }
          ... on SanityTextBlock {
            _key
            _type
            _rawTextArea(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`

const ProjectTemplate = ({ data, pageContext }) => {
  const project = data.project
  const seoDescription = project.shortDescription
  const seoTitle = project.title
  const seoImage = project.featuredimage?.image.asset.url

  const { activeMenuItem, setActiveMenuItem } = useContext(AVCOContext)

  useEffect(() => {
    setActiveMenuItem("projects")
  }, [activeMenuItem, setActiveMenuItem])

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} image={seoImage} />

      <ProjectPage {...project} />
    </>
  )
}

export default ProjectTemplate
