// extracted by mini-css-extract-plugin
export var blockRichtext = "ProjectPage-module--block-richtext--fcf8a";
export var bodyBlock = "ProjectPage-module--body-block--3c0e7";
export var bodyIntro = "ProjectPage-module--body-intro--25bb9";
export var cover = "ProjectPage-module--cover--627cb";
export var header1 = "ProjectPage-module--header1--addb0";
export var header2 = "ProjectPage-module--header2--a7853";
export var header3 = "ProjectPage-module--header3--cecb3";
export var header4 = "ProjectPage-module--header4--d11c1";
export var header5 = "ProjectPage-module--header5--e4a90";
export var moreLess = "ProjectPage-module--more-less--70ded";
export var notCover = "ProjectPage-module--not-cover--a0c9c";
export var pageContainer = "ProjectPage-module--page-container--86fd4";
export var projectImage = "ProjectPage-module--project-image--c7a84";
export var projectInfo = "ProjectPage-module--project-info--bec1f";
export var readLess = "ProjectPage-module--read-less--37ac2";
export var readMore = "ProjectPage-module--read-more--fc222";
export var videoMobile = "ProjectPage-module--video-mobile--7b837";
export var whiteBackground = "ProjectPage-module--white-background--6e4a5";