import React from "react"
import classNames from "classnames"

import * as styles from "./ListItem.module.scss"

const ListItem = ({
  label,
  children,
  size = "normal",
  leftBorder = false,
  rightBorder = false,
  topBorder = true,
  bottomBorder = true,
  className,
}) => {
  const cls = classNames(
    styles.item,
    [styles[size]],
    {
      [styles.topBorder]: topBorder,
      [styles.rightBorder]: rightBorder,
      [styles.bottomBorder]: bottomBorder,
      [styles.leftBorder]: leftBorder,
    },
    className
  )

  return (
    <div className={cls}>
      <span className={styles.label}>{label}</span>
      <span>{children}</span>
    </div>
  )
}

export { ListItem }
