import React from "react"
import PropTypes from "prop-types"
import { Wrapper } from "../../../layout/Wrapper"
import { ImageBlock } from "../../../common/ImageBlock"
import { MediaBlock } from "../../../common/MediaBlock"
import { Mp4sBlock } from "../../../common/Mp4sBlock"

const PageBlock = ({
  children,
  images,
  description,
  format,
  type,
  url,
  altText,
  mp4s,
  placeholder,
}) => {
  return (
    <div>
      <div>
        {type === "images" && (
          <ImageBlock
            format={format}
            images={images}
            description={description}
          />
        )}
        {type === "media" && (
          <MediaBlock
            format={format}
            url={url}
            description={description}
            altText={altText}
          />
        )}
        {type === "video" && <Mp4sBlock format={format} mp4s={mp4s} />}
      </div>
      <div>
        <Wrapper narrow>{children}</Wrapper>
      </div>
    </div>
  )
}

PageBlock.propTypes = {
  children: PropTypes.node,
  images: PropTypes.array,
  description: PropTypes.string,
  format: PropTypes.string,
  type: PropTypes.string,
  urls: PropTypes.array,
}

export { PageBlock }
