// extracted by mini-css-extract-plugin
export var cover = "MediaBlock-module--cover--da226";
export var description = "MediaBlock-module--description--2655c";
export var double = "MediaBlock-module--double--7f492";
export var header1 = "MediaBlock-module--header1--43a43";
export var header2 = "MediaBlock-module--header2--8506a";
export var header3 = "MediaBlock-module--header3--81a38";
export var header4 = "MediaBlock-module--header4--59477";
export var header5 = "MediaBlock-module--header5--68f9e";
export var mediaBlock = "MediaBlock-module--media-block--c12d9";
export var mobile = "MediaBlock-module--mobile--42951";