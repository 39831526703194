import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Wrapper } from "../../layout/Wrapper"
import { Container } from "../../layout/Container"
import classNames from "classnames"

import * as styles from "./ImageBlock.module.scss"

const ImageBlock = ({
  format,
  images,
  description,
  className,
  noPadding = false,
}) => {
  return (
    <div className={classNames(styles.imageBlock, className)}>
      {format === "cover" ? (
        <Container className={styles.cover}>
          {images.map((item, index) => {
            return (
              <div
                className={styles.coverItem}
                key={`image-${item?.altText}-${index}`}
              >
                {item.image?.asset?.mimeType !== "image/gif" ? (
                  <GatsbyImage
                    image={item.image?.asset?.gatsbyImageData}
                    alt={item?.alText ? item?.altText : item?.caption}
                    loading="eager"
                  />
                ) : (
                  <img
                    src={item?.image?.asset?.url}
                    alt={item?.altText ? item?.altText : item?.caption}
                  />
                )}
                {item.caption && (
                  <div className={styles.caption}>{item.caption}</div>
                )}
              </div>
            )
          })}

          <div className={styles.description}>{description}</div>
        </Container>
      ) : (
        <>
          <Wrapper noPadding>
            <div
              className={classNames({
                [styles.single]: format === "single",
                [styles.double]: format === "double",
              })}
            >
              {images.map((item, index) => {
                return (
                  <div key={`image-${item?.alText}-${index}`}>
                    {item.image?.asset?.mimeType !== "image/gif" ? (
                      <GatsbyImage
                        image={item.image?.asset?.gatsbyImageData}
                        alt={item?.altText ? item?.altText : item?.caption}
                        loading="eager"
                      />
                    ) : (
                      <img
                        src={item?.image?.asset?.url}
                        alt={item?.altText ? item?.altText : item?.caption}
                      />
                    )}
                    {item.caption && (
                      <div className={styles.caption}>{item.caption}</div>
                    )}
                  </div>
                )
              })}
              <div className={styles.description}>{description}</div>
            </div>
          </Wrapper>
        </>
      )}
    </div>
  )
}

export { ImageBlock }
