import React from "react"
import { Wrapper } from "../../layout/Wrapper"
import { Container } from "../../layout/Container"
import classNames from "classnames"
import { HostedEmbed } from "../HostedEmbed"
import * as styles from "./MediaBlock.module.scss"

const MediaBlock = ({ format, url, altText, description, placeholder }) => {
  return (
    <div className={styles.mediaBlock}>
      {format === "cover" ? (
        <>
          <Container className={styles.cover}>
            <HostedEmbed mediaUrl={url} altText={altText} />
          </Container>
          <Wrapper>
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </Wrapper>
        </>
      ) : (
        <Wrapper
          className={classNames({
            [styles.double]: format === "double",
          })}
        >
          <HostedEmbed
            mediaUrl={url}
            className={classNames({
              [styles.mobile]: format === "mobile",
            })}
            placeholderImage={placeholder}
            altText={altText}
          />
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </Wrapper>
      )}
    </div>
  )
}

export { MediaBlock }
