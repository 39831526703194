import React from "react"
import { Wrapper } from "../../layout/Wrapper"
import { Container } from "../../layout/Container"
import classNames from "classnames"
import { HostedEmbed } from "../HostedEmbed"
import * as styles from "./Mp4sBlock.module.scss"

const Mp4sBlock = ({ format, mp4s }) => {
  return (
    <div className={styles.mediaBlock}>
      {format === "cover" ? (
        <>
          <Container className={styles.cover}>
            {mp4s.map((item, idx) => {
              return (
                <div>
                  <HostedEmbed
                    mediaUrl={item.mp4Upload.source.asset.url}
                    altText={item.altText}
                    placeholderImage={item.placeholderImage}
                  />
                  <div className={styles.caption}>{item.mediaDescription}</div>
                </div>
              )
            })}
          </Container>
        </>
      ) : (
        <Wrapper
          className={classNames({
            [styles.double]: format === "double",
          })}
        >
          {mp4s.map((item, idx) => {
            return (
              <div>
                <HostedEmbed
                  mediaUrl={item.mp4Upload.source.asset.url}
                  altText={item.altText}
                  placeholderImage={item.placeholderImage}
                />
                <div className={styles.caption}>{item.mediaDescription}</div>
              </div>
            )
          })}
        </Wrapper>
      )}
    </div>
  )
}

export { Mp4sBlock }
