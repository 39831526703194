// extracted by mini-css-extract-plugin
export var caption = "ImageBlock-module--caption--84340";
export var cover = "ImageBlock-module--cover--1e11a";
export var coverItem = "ImageBlock-module--cover-item--08af2";
export var description = "ImageBlock-module--description--80bae";
export var double = "ImageBlock-module--double--d31ac";
export var header1 = "ImageBlock-module--header1--4ba3d";
export var header2 = "ImageBlock-module--header2--9ee88";
export var header3 = "ImageBlock-module--header3--025e4";
export var header4 = "ImageBlock-module--header4--df804";
export var header5 = "ImageBlock-module--header5--8cdd8";
export var imageBlock = "ImageBlock-module--image-block--c2cc0";
export var single = "ImageBlock-module--single--c3a6e";